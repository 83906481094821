export const ROUTES = {
  INTRO: 'intro',
  LOGOS: 'logos',
  SIMU: 'simulation',
  PANO: 'panoramas',
  G1: 'quai-gustave-ador',
  G2: 'pont-de-bergues',
  G3: 'quai-gustave-ador-2',
  LOGO: 'logo',
  INFOS: 'infos',
  SENT: 'sent'
}