import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  redirect,
  Route,
  RouterProvider
} from "react-router-dom";
import './App.css';
import './i18n';
import BaseLayout from "./components/BaseLayout";
import Home from "./pages/Home";
import Panel from "./components/Panel";
import Intro from "./components/Intro";
import Simulation from "./components/Simulation";
import Logos from "./components/Logos";
import Panoramas from "./components/Panoramas";
import {ROUTES} from "./constants/routes";
import {RendererContextProvider} from "./RendererContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => {
  }
  console.error = () => {
  }
  console.debug = () => {
  }
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<BaseLayout/>}>
      <Route path=":panorama"
             id={"root"}
             element={<Home/>}
             loader={async ({params}: any) => {
               const panorama = [ROUTES.G1, ROUTES.G2, ROUTES.G3];
               if (panorama.includes(params.panorama)) {
          
                 const dataRaw = await fetch(
                   `/tiles/${params.panorama}/data.json`
                 );
                 const data = await dataRaw.json();
          
                 const placehodersRaw = await fetch(
                   `/tiles/${params.panorama}/placeholders.json`
                 );
                 const placehoders = await placehodersRaw.json();
          
                 return new Response(JSON.stringify({...data, ...placehoders}), {
                   status: 200,
                   headers: {
                     "Content-Type": "application/json; utf-8",
                   },
                 });
          
               } else {
                 return redirect(`/${ROUTES.G1}`);
               }
             }}
      >
        <Route
          id={"panel"}
          element={<Panel/>}
          loader={async ({params}: any) => {
            
            const logosRaw = await fetch(
              `/logos.json`
            );
            const logos = await logosRaw.json();
            
            return new Response(JSON.stringify(logos), {
              status: 200,
              headers: {
                "Content-Type": "application/json; utf-8",
              },
            });
            
          }}
        >
          <Route path={ROUTES.INTRO} element={<Intro/>}></Route>
          <Route path={`${ROUTES.LOGOS}/:spotID/:logoID`} element={<Logos/>}></Route>
          <Route path={`${ROUTES.LOGOS}/:spotID`} element={<Logos/>}></Route>
          <Route path={`${ROUTES.LOGOS}`} element={<Logos/>}></Route>
          <Route path={`${ROUTES.SIMU}`} element={<Simulation/>}></Route>
          <Route path={`${ROUTES.SIMU}/:spotID`} element={<Simulation/>}></Route>
          <Route path={`${ROUTES.PANO}`} element={<Panoramas/>}></Route>
          <Route path={``} element={<Navigate to={ROUTES.INTRO} replace/>}/>
        </Route>
      </Route>
      <Route path={''} element={<Navigate to={ROUTES.G1} replace/>}/>
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<>..</>}>
      <RendererContextProvider>
        <RouterProvider router={router}/>
      </RendererContextProvider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
